import React from "react";
import PropTypes from "prop-types";
import moment from "helpers/moment";

const FormattedDate = ({ date, weekDay, time, className, ...props }) => {
  const momentDate = moment(Number.parseInt(date, 10));
  return (
    <span {...props} className={`formatted-date ${className}`}>
      {weekDay && momentDate.format("dddd, ")}
      {momentDate.format("Do")} {momentDate.format("MMM YYYY").toUpperCase()}
      {time && momentDate.format(" [at] h:mm A")}
    </span>
  );
};

FormattedDate.propTypes = {
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  className: PropTypes.string,
  weekDay: PropTypes.bool,
  time: PropTypes.bool,
};

FormattedDate.defaultProps = {
  className: "",
  weekDay: false,
  time: false,
};

export default FormattedDate;
