import PropTypes from "prop-types";

export default PropTypes.shape({
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  currentPrice: PropTypes.number.isRequired,
  numberOfBids: PropTypes.number.isRequired,
  auctionEnd: PropTypes.number.isRequired,
});
