/* eslint-disable react/no-danger */
import React from "react";
import PropTypes from "prop-types";
import Svg from "components/elements/svg";
import moment from "helpers/moment";

const NotificationBox = ({
  type,
  children,
  dismissable,
  timestamp,
  onDismiss,
}) => {
  if (type && children) {
    return (
      <div className={`notification-box ${type}`}>
        <Svg className="icon" src="info-icon" />
        <div className="notification-box__content">
          {timestamp && (
            <p className="date">
              {moment(timestamp * 1000).format("DD.MM.Y, HH:mm")}
            </p>
          )}
          {typeof children === "string" && (
            <div dangerouslySetInnerHTML={{ __html: children }} />
          )}
          {typeof children === "object" && children}

          {dismissable && (
            <button className="dismiss" onClick={() => onDismiss()}>
              <Svg src="x" className="icon" />
            </button>
          )}
        </div>
      </div>
    );
  }

  return null;
};

NotificationBox.propTypes = {
  type: PropTypes.oneOf(["success", "warning", "danger", "info"]),
  children: PropTypes.node,
  dismissable: PropTypes.bool,
  timestamp: PropTypes.number,
  onDismiss: PropTypes.func,
};

NotificationBox.defaultProps = {
  type: null,
  children: null,
  dismissable: false,
  timestamp: null,
  onDismiss: () => null,
};

export default NotificationBox;
