import React from "react";
import { Oswald } from "next/font/google";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const oswald = Oswald({ weight: ["400"], subsets: ["latin"] });

class Countdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      secondsRemaining: null,
    };
  }

  componentDidMount() {
    this.tick();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { time, serverTimeDifference } = this.props;
    const { secondsRemaining } = this.state;
    if (newProps.time > time && secondsRemaining <= 0) {
      const newSecondsRemaining = Math.round(
        time - Date.now() / 1000 - serverTimeDifference,
      );
      this.setState(
        {
          secondsRemaining: newSecondsRemaining,
        },
        () => {
          this.tick();
        },
      );
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  tick() {
    const { time, serverTimeDifference } = this.props;
    const secondsRemaining = Math.round(
      time - Date.now() / 1000 - serverTimeDifference,
    );

    if (secondsRemaining !== this.state.secondsRemaining) {
      this.setState({
        secondsRemaining,
      });
    }

    if (secondsRemaining <= 0) {
      clearTimeout(this.timeout);
      this.props.onFinish(true);
    } else {
      this.timeout = setTimeout(() => this.tick(), 10);
    }
  }

  format(value) {
    return value >= 10 ? value : `0${value}`;
  }

  render() {
    let { secondsRemaining } = this.state;
    const { className } = this.props;
    let { theme } = this.props;

    const days = Math.max(0, Math.floor(secondsRemaining / 86400));
    secondsRemaining -= days * 86400;
    const hours = Math.max(0, Math.floor(secondsRemaining / 3600));
    secondsRemaining -= hours * 3600;
    const minutes = Math.max(0, Math.floor(secondsRemaining / 60));
    const seconds = Math.max(0, Math.floor(secondsRemaining % 60));

    if (
      (days === 0 && hours === 0 && minutes < 5) ||
      (days === 0 && hours === 0 && minutes === 5 && seconds === 0)
    ) {
      theme = "red";
    }

    const classNames = [
      oswald.className,
      "countdown",
      `countdown--${theme}`,
      className,
    ];

    if (secondsRemaining === null) {
      classNames.push("inactive");
    }

    return (
      <div className={classNames.join(" ")}>
        <div className="countdown__num">
          {this.format(days)}
          <small>d</small>
        </div>
        <div className="countdown__num">
          {this.format(hours)}
          <small>h</small>
        </div>
        <div className="countdown__num">
          {this.format(minutes)}
          <small>m</small>
        </div>
        <div className="countdown__num">
          {this.format(seconds)}
          <small>s</small>
        </div>
      </div>
    );
  }
}

Countdown.propTypes = {
  time: PropTypes.number.isRequired,
  className: PropTypes.string,
  theme: PropTypes.oneOf(["dark", "light", "red"]).isRequired,
  serverTimeDifference: PropTypes.number.isRequired,
  onFinish: PropTypes.func,
};

Countdown.defaultProps = {
  className: "",
  onFinish: () => null,
};

const mapStateToProps = (state) => ({
  serverTimeDifference: state.app.serverTimeDifference,
});

export default connect(mapStateToProps, {})(Countdown);
