import React from "react";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withAuth from "components/core/with-auth";
import Countdown from "components/elements/countdown";
import FormattedDate from "components/elements/formatted-date";
import FormattedPrice from "components/elements/formatted-price";
import Link from "components/elements/link";
import NotificationBox from "components/elements/notification-box";
import SVG from "components/elements/svg";
import config from "config";
import moment from "helpers/moment";
import PropertyPropType from "helpers/prop-types/property-index";
import {
  getBiddingPriceTitle,
  getCurrentPrice,
  isBuyNowAvailable,
} from "helpers/property";
import Api from "services/api";

const isEnded = (property) => Date.now() > property.auctionEnd * 1000;
const isBidding = (props) => props.winning !== null && props.yourBid !== null;

class PropertyBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      property: props.property,
      timerFinished: isEnded(props.property),
    };
    this.propertyStartTimeout = 0;
  }

  componentDidMount() {
    const { pusher } = window;
    this.socket = pusher.subscribe("properties-channel");
    this.socket.bind(`property.updated.${this.state.property.id}`, (event) => {
      if (event.data.property) {
        const newProperty = { ...this.state.property, ...event.data.property };
        newProperty.finalChecks = false;
        const propertyUpdated = this.props.onPropertyUpdate(newProperty);
        if (!propertyUpdated) {
          this.setState({ property: newProperty });
        }
        clearTimeout(this.timeout);
        this.setState({ property: newProperty });
      }
    });
    const { user } = this.props;
    if (user.id) {
      this.socket2 = pusher.subscribe("users-channel");
      this.socket2.bind(
        `property.${this.state.property.id}.user.${user.id}.bid-update`,
        (event) => {
          if (event.data.bidAmount) {
            const { property } = this.state;
            property.yourBid = event.data.bidAmount;
            property.winning = event.data.winning;
            this.setState({ property });
          }
        },
      );
    }
    const { property, serverTimeDifference, tokens } = this.props;
    if (moment(property.biddingStartDate * 1000).isAfter(moment())) {
      clearTimeout(this.propertyStartTimeout);
      const diff =
        property.biddingStartDate * 1000 -
        serverTimeDifference * 1000 -
        Date.now();
      const maxInteger = 2 ** 31 - 1;
      this.propertyStartTimeout = setTimeout(
        () => {
          new Api().get(`/property-events/${property.id}`, {}, tokens.token);
        },
        diff > maxInteger ? maxInteger : diff,
      );
    }
  }

  componentWillUnmount() {
    clearTimeout(this.propertyStartTimeout);
    if (this.socket) {
      this.socket.unbind();
    }
    if (this.socket2) {
      this.socket2.unbind();
    }
  }

  handlePropertyEnd(property) {
    if (property !== null && !this.state.timerFinished) {
      const newProperty = property;
      newProperty.finalChecks = true;
      this.setState(
        {
          timerFinished: true,
          property: newProperty,
        },
        () => {
          this.timeout = setTimeout(() => {
            new Api().post(`/properties/${property.slug}/auction-end`);
          }, 10000);
        },
      );
    }
  }

  handleDelete(property) {
    this.props.handleDeleteButtonClick(property);
  }

  render() {
    const { countdownTheme, className, user, showDeleteButton, showYourBid } =
      this.props;
    const { property } = this.state;
    const {
      title,
      slug,
      id,
      thumbnail,
      currentPrice,
      numberOfBids,
      auctionEnd,
    } = property;
    const bidClass = isBidding(this.props) ? " bid" : "";
    const endedClass = isEnded(property) ? " ended" : "";
    const linkSlug = slug.length > 0 ? slug : id.toString();
    const notSoldClass =
      false && user.id && property.author === user.id && isEnded(property)
        ? " not-sold "
        : "";
    const isFuture =
      !!property.biddingStartDate &&
      moment(property.biddingStartDate * 1000).isAfter(moment());
    const bidWindowClass =
      !property.comingSoon && property.status === "publish"
        ? " bidding-window"
        : "";
    const showPrice =
      !property.comingSoon || (property.comingSoon && property.showPrice);

    return (
      <div
        className={`property-box ${className}${bidClass}${endedClass}${notSoldClass}`}
      >
        <div
          className={`property-box__thumbnail${bidWindowClass}`}
          style={{ backgroundImage: `url(${thumbnail})` }}
        >
          <div className="property-box__thumbnail__price">
            {!isEnded(property) && <p>{getBiddingPriceTitle(property)}:</p>}
            {showPrice ? (
              <p>
                <FormattedPrice price={getCurrentPrice(property)} />
              </p>
            ) : (
              <p>£ TBC</p>
            )}
          </div>
          {!isFuture && !isBuyNowAvailable(property) && (
            <p className="property-box__thumbnail__bids">
              {numberOfBids} {pluralize("bid", numberOfBids)}
            </p>
          )}
          {property.comingSoon && (
            <div className="property-box__starts bottom-right">
              <p>Coming Soon</p>
            </div>
          )}
          {!property.comingSoon &&
            ["publish", "draft"].includes(property.status) && (
              <div
                className={
                  isFuture
                    ? "property-box__starts bottom-right"
                    : "property-box__starts"
                }
              >
                {isFuture && <p>Bidding opens on:</p>}
                {isFuture && (
                  <p>
                    <FormattedDate
                      date={moment(property.biddingStartDate * 1000).format(
                        "x",
                      )}
                    />
                  </p>
                )}
                {!isFuture && <span>Bidding window now open</span>}
              </div>
            )}
          <div className="property-box__thumbnail__button">
            <button className="btn btn-white btn-icon--right" tabIndex="-1">
              <span>View property</span>
              <SVG src="arrow-right" />
            </button>
          </div>
        </div>

        <p className="property-box__title">
          <span>{title}</span>
        </p>
        {property.status !== "draft" && !property.comingSoon && (
          <p className="property-box__ends">
            {isEnded(property) ? "Ended" : "Ends"}:{" "}
            <FormattedDate weekDay date={property.auctionEnd * 1000} />
          </p>
        )}

        {false &&
          user.id &&
          property.author === user.id &&
          property.status !== "draft" &&
          isEnded(property) && (
            <div className="property-box__duplicate-button">
              <button
                className="btn btn-primary with-arrow"
                onClick={() => null}
              >
                New Auction
                <SVG src="arrow-right" className="arrow" />
              </button>
            </div>
          )}

        {user.id && property.yourBid && showYourBid && (
          <div
            className={`property-box__user-bid ${
              property.winning ? "winning" : "losing"
            } ${endedClass}`}
          >
            {!isEnded(property) && (
              <p>
                Your maximum bid: <FormattedPrice price={property.yourBid} />
              </p>
            )}
            {isEnded(property) && (
              <div className="not-won-info">
                <SVG src="shopping-cart-fail" className="not-won" />
                <span>Ended</span>
              </div>
            )}
          </div>
        )}
        {!property.comingSoon && !isEnded(property) && (
          <div className="property-box__countdown">
            <Countdown
              time={auctionEnd}
              theme={countdownTheme}
              onFinish={() => {
                this.handlePropertyEnd(property);
              }}
            />
          </div>
        )}
        {property.finalChecks && (
          <NotificationBox type="info">Checking...</NotificationBox>
        )}
        {isEnded(property) && showDeleteButton && (
          <button
            className="delete-btn"
            onClick={() => this.handleDelete(property)}
          >
            <SVG src="x-in-circle" className="icon" />
          </button>
        )}
        <Link to={`properties/${linkSlug}`} title={title} />
      </div>
    );
  }
}

PropertyBox.propTypes = {
  property: PropertyPropType.isRequired,
  className: PropTypes.string,
  countdownTheme: PropTypes.oneOf(["dark", "light"]),
  handleDeleteButtonClick: PropTypes.func,
  serverTimeDifference: PropTypes.number,
  tokens: PropTypes.shape({}),
  user: PropTypes.shape({
    id: PropTypes.number,
  }),
  showDeleteButton: PropTypes.bool,
  showYourBid: PropTypes.bool,
  onPropertyUpdate: PropTypes.func,
};

PropertyBox.defaultProps = {
  className: "",
  thumbnail: config.property.placeholder,
  countdownTheme: "dark",
  user: {},
  handleDeleteButtonClick: () => null,
  serverTimeDifference: 0,
  tokens: {},
  showDeleteButton: false,
  showYourBid: false,
  onPropertyUpdate: () => null,
};

export default connect((state) => ({
  serverTimeDifference: state.app.serverTimeDifference,
}))(withAuth(PropertyBox));
