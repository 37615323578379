import moment from "helpers/moment";
import { PropertyDetailed, PropertySimple } from "index";

export const getBidOptions = (startPrice = 0) => {
  // @ts-expect-error legacy
  if (Number.isNaN(Number.parseInt(startPrice, 10))) {
    throw new TypeError("Not a number passed as property start price");
  }

  if (startPrice < 250000) {
    return [500, 1000, 2500, 5000];
  }
  if (startPrice < 900000) {
    return [1000, 2000, 5000, 10000];
  }
  return [5000, 10000, 15000, 25000];
};

export const getBidInterval = (startPrice = 0) => {
  // @ts-expect-error legacy
  if (Number.isNaN(Number.parseInt(startPrice, 10))) {
    throw new TypeError("Not a number passed as property start price");
  }

  if (startPrice < 250000) {
    return 500;
  }
  if (startPrice < 900000) {
    return 1000;
  }
  return 5000;
};

export const isBuyNowAvailable = (
  property: PropertyDetailed | PropertySimple,
) => {
  return property.auctionType === "buyNow";
};

export function getBiddingPriceTitle(
  property: PropertyDetailed | PropertySimple,
) {
  if (isBuyNowAvailable(property)) {
    return "Buy now price";
  }

  const hasBids =
    ("bids" in property && property.bids.length > 0) ||
    ("numberOfBids" in property && property.numberOfBids > 0);

  if (property.status === "sold") {
    return "Sold";
  }

  if (property.status === "ended" && hasBids) {
    return "Highest bid";
  }

  if (hasBids) {
    return "Current Bid";
  }

  return "Start price";
}

export function getCurrentPrice(property: PropertyDetailed | PropertySimple) {
  if (isBuyNowAvailable(property)) {
    return property.buyNowPrice;
  }

  return property.currentPrice;
}

export function isOpenDayClosed(date: moment.Moment) {
  const expirationDate = moment(date.toISOString())
    .subtract(1, "day")
    .set({ hour: 14, minute: 0, second: 0, millisecond: 0 });
  return moment().isAfter(expirationDate);
}
